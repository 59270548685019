<template>
    <v-container fluid class="py-6">
        <v-snackbar
                top
                v-model="snackbar.visible"
                :color="snackbar.color"
                class="snackbar-shadow"
        >
            <div class="d-flex align-start alert-notify">
                <v-icon size="24" class="text-white mr-5"
                >ni ni-bell-55
                </v-icon
                >
                <p class="mb-0">
                    <span class="font-size-root font-weight-600"
                    >{{snackbar.name}}</span
                    >
                    <br/>
                    {{error_msg}}
                </p>
            </div>

            <template v-slot:action="{ attrs }">
                <v-btn
                        icon
                        elevation="0"
                        max-width="136"
                        :ripple="false"
                        height="43"
                        class="font-weight-600 text-capitalize py-3 px-6 rounded-sm"
                        color="rgba(255,255,255, .85)"
                        @click="snackbar.visible = false"
                        v-bind="attrs"
                >
                    <v-icon size="13">fas fa-times</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
        <v-row>
            <v-col cols="12">
                <v-card class="card-shadow border-radius-xl">
                    <v-card-text class="px-0 py-0">
                        <v-simple-table class="table border-radius-xl">
                            <template v-slot:default>
                                <thead>
                                <tr>


                                    <th
                                            class="
                        text-center text-uppercase text-secondary text-xxs
                        font-weight-bolder
                        opacity-7
                      "
                                    >
                                        {{$t('group.Name')}}
                                    </th>

                                    <th
                                            class="
                         text-center text-uppercase text-secondary text-xxs
                        font-weight-bolder
                        opacity-7
                      "
                                    >
                                        {{$t('role.Roles')}}
                                    </th>


                                    <th
                                            class="
                        text-center text-uppercase text-secondary text-xxs
                        font-weight-bolder
                        opacity-7
                      "
                                    >
                                        {{$t('general.Created at')}}
                                    </th>
                                    <th
                                            class="
                        text-center text-uppercase text-secondary text-xxs
                        font-weight-bolder
                        opacity-7
                      "
                                    >
                                        {{$t('general.Updatet at')}}
                                    </th>
                                    <th
                                            class="
                        text-center text-uppercase text-secondary text-xxs
                        font-weight-bolder
                        opacity-7
                      "
                                    >
                                        ID
                                    </th>
                                    <th>{{$t('general.Action')}}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(item, i) in permissions" :key="item.id">
                                    <td
                                            :class="{ 'border-bottom': i != permissions.length - 1 }"
                                            class="text-center"
                                    >
                      <span class="text-secondary text-center">{{
                        item.name
                      }}</span>
                                    </td>
                                    <td
                                            :class="{ 'border-bottom': i != permissions.length - 1 }"
                                            class="text-center"
                                    >

                                        <template v-if="item.roles.length > 0">
                                            <v-chip
                                                    small chip
                                                    v-for="(role,index) in item.roles"
                                                    :key="index"
                                                    class="ma-2"
                                                    close
                                                    color="primary"
                                                    outlined
                                                    @click="unAssignRole(role.id,item.id,role.name)"
                                            >
                                                {{role.name}}
                                            </v-chip>
                                        </template>


                                    </td>
                                    <td
                                            :class="{ 'border-bottom': i != permissions.length - 1 }"
                                            class="text-center"
                                    >
                      <span class="text-secondary text-sm">{{
                        item.created_at | formatDate
                      }}</span>
                                    </td>
                                    <td
                                            :class="{ 'border-bottom': i != permissions.length - 1 }"
                                            class="text-center"
                                    >
                      <span class="text-secondary text-sm">{{
                        item.updated_at | formatDate
                      }}</span>
                                    </td>
                                    <td
                                            :class="{ 'border-bottom': i != permissions.length - 1 }"
                                            class="text-center"
                                    >
                                        <span class="text-secondary text-sm">{{ item.id }}</span>
                                    </td>
                                    <td>
                                        <v-btn
                                                @click="DeletePermission(item.id)"
                                                icon
                                        >
                                            <v-icon size="14">mdi-delete</v-icon>
                                        </v-btn>
                                        <template>
                                            <router-link
                                                    :to="'/permissions/' + item.id"
                                                    icon
                                                    elevation="0"
                                                    :ripple="false"
                                                    height="28"
                                                    min-width="36"
                                                    width="36"
                                                    class="btn-ls me-2 my-2 rounded-sm"
                                                    color="#67748e"
                                            >
                                                <v-icon size="14">mdi-pencil</v-icon>
                                            </router-link>
                                        </template>
                                    </td>
                                </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    export default {
        name: "Dashboard",
        components: {},
        data() {
            return {
                permissions: {},
                error_msg:"",
                snackbar: {
                    color: "#f5365c",
                    class: "danger",
                    name: "Danger",
                    visible: false
                },
            };
        },
        methods: {
            getPermissions() {
                this.$http.get("/permissions").then(
                    (response) => {
                        this.permissions = response.data.data;
                    },
                    (error) => {
                        // console.log(error);
                    }
                );
            },

            DeletePermission(id){
                this.$swal({
                    title: "Are you sure you want to delete this role?",
                    text: "You won't be able to revert this!",
                    showCancelButton: true,
                    confirmButtonText: "Delete",
                    cancelButtonText: "Keep it!",
                    customClass: {
                        confirmButton: "btn bg-gradient-danger",
                        cancelButton: "btn bg-gradient-success",
                    },
                    preConfirm: () => {
                        axios.post("permissions/delete/"+id)
                            .then((response) => {
                                this.error_msg = response.data.message
                                this.snackbar.color = "#2dce89"
                                this.snackbar.class = "success"
                                this.snackbar.name = "Success"
                                this.snackbar.visible = true
                                this.getPermissions()
                            })
                            .catch((error) => {
                                this.loading = false;
                                this.error_msg =
                                    (error.response && error.response.data.message) ||
                                    error.message ||
                                    error.toString();
                                this.snackbar.color = "#f5365c";
                                this.snackbar.class = "error";
                                this.snackbar.name = "Error";
                                this.snackbar.visible = true;
                            });
                    }
                });
            },

            unAssignRole(role_id,permission_id,role_name){
                this.$swal({
                    title: "Are you sure you want to remove this permission from role "+ role_name+ "?",
                    text: "You can re assigned it any time you want",
                    showCancelButton: true,
                    confirmButtonText: "Remove",
                    cancelButtonText: "Keep it!",
                    customClass: {
                        confirmButton: "btn bg-gradient-danger",
                        cancelButton: "btn bg-gradient-success",
                    },
                    preConfirm: () => {
                        axios.post("permissions/un-assign-role/"+permission_id,{role:role_id})
                            .then((response) => {
                                this.error_msg = response.data.message
                                this.snackbar.color = "#2dce89"
                                this.snackbar.class = "success"
                                this.snackbar.name = "Success"
                                this.snackbar.visible = true
                                this.getPermissions()
                            })
                            .catch((error) => {
                                this.loading = false;
                                this.error_msg =
                                    (error.response && error.response.data.message) ||
                                    error.message ||
                                    error.toString();
                                this.snackbar.color = "#f5365c";
                                this.snackbar.class = "error";
                                this.snackbar.name = "Error";
                                this.snackbar.visible = true;
                            });
                    }
                });
            }
        },
        mounted() {
            this.getPermissions();
        },
    };
</script>
